import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  /* tslint:disable */
  readonly DEFAULT_DESCRIPTION = (environment.project === 'cr' ? 'Join Code Race – the global coding competition for kids 8-14! Solve fun Scratch & Python challenges, climb the Elo ranking, and compete in frequent contests.' : 'Coding with Kids teaches computer programming to elementary and middle school children. We offer Coder\'s Ladder classes, enrichment classes, and camps.');
  readonly DEFAULT_KEYWORDS = (environment.project === 'cr' ? 'Code Race, kids coding competitions, coding contests for kids, Scratch & Python coding challenges, kids programming competitions, STEM coding contests, online coding challenges for kids, beginner coding tournaments, fun coding games for kids' : 'Coding with Kids, Coder’s Ladder®, Coder’s Pathways®, Summer STEM camps, After school Coding camps, Live Online Coding Camps, Coding Camps Near Me');
  readonly DEFAULT_ROBOTS = (environment.shortName === 'prod' ? 'all' : 'none');
  readonly DEFAULT_TITLE = environment.domainName;
  /* tslint:enable */

  constructor(
    private meta: Meta,
    private title: Title
  ) {
    this.meta.updateTag({ name: 'og:type', content: 'website' });
  }

  updateDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description || this.DEFAULT_DESCRIPTION });
    this.meta.updateTag({ property: 'og:description', content: description || this.DEFAULT_DESCRIPTION });
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: (keywords || this.DEFAULT_KEYWORDS) });
  }

  updateName(name: string, content: string) {
    this.meta.updateTag({ name: name, content: content });
  }

  updateProperty(property: string, content: string) {
    this.meta.updateTag({ property: property, content: content });
  }

  updateRobots(robots: string) {
    this.meta.updateTag({ name: 'robots', content: (robots || this.DEFAULT_ROBOTS) });
  }

  updateTitle(title: string) {
    this.title.setTitle((title || this.DEFAULT_TITLE));
    this.meta.updateTag({ property: 'og:title', content: (title || this.DEFAULT_TITLE) });
  }

  updateUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }
}
